import { useCallback, useMemo } from "react";
import { EWebViewRoute, EWebViewEvent } from "../types";

const useMPIntegration = () => {
  const isAndroidWebView = useMemo(() => !!window?.mobile, []);
  const isIosWebView = useMemo(() => !!window?.webkit, []);

  const notifyMobileOfUrlChange = useCallback(
    (url: EWebViewRoute) => {
      try {
        if (isIosWebView) {
          window.webkit.messageHandlers.iosEvent.postMessage({
            [EWebViewEvent.WebEvent]: url
          });
        }
        if (isAndroidWebView) {
          window.mobile[EWebViewEvent.WebEvent](url);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [isAndroidWebView, isIosWebView]
  );

  return { notifyMobileOfUrlChange };
};

export default useMPIntegration;
