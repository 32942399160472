import React from 'react';
import Slider from "react-slick";
import classes from './style.module.scss'

import sliderImg1 from '../../images/slider1.png'
import sliderImg2 from '../../images/slider2.png'
import sliderImg3 from '../../images/slider3.png'

export const ProductSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
    return (
        <div>
          <Slider {...settings} className={classes.slider}>
            <div className={classes.slide}>
              <img src={sliderImg1} alt='slide'/>
            </div>
            <div className={classes.slide}>
              <img src={sliderImg2} alt='slide' />
            </div>
            <div className={classes.slide}>
              <img src={sliderImg3} alt='slide' />
            </div>
          </Slider>
        </div>
      );
}