import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import classes from './style.module.scss'
import arrow from '../../images/back.svg'
import { useNavigate } from 'react-router-dom';
import { Path } from '../../types';

dayjs.locale('ru'); 

interface Props {
    fullPrice: string;
    splitPrice: string;
    img: string;
    name: string;
    description: string;
}

export const ProductSchedule:React.FC<Props> = ({fullPrice, splitPrice, img, name, description }) => {
    const navigate = useNavigate()

    const handleBack = () => {
        navigate(Path.Offer)
    }

    const handleForward = () => {
        navigate(Path.Error)
    }

    return (
        <div className={classes.shedule}>
            <div className={classes.back}>
                <button onClick={handleBack}><img src={arrow} alt='arrow' /></button>
            </div>
            <div className={classes.wrapper}>
                <h2 className={classes.title}>Ваш график платежей</h2>
                <p className={classes.description}>Оплата обычной банковской картой, только по частям. Без процентов и переплат</p>
                <div className={classes.header}>
                    <img src={img} alt='icon' />
                    <div className={classes.header__info}>
                        <span className={classes.header__info_name}>{name}</span>
                        <span className={classes.header__info_description}>{description}</span>
                    </div>
                    <div className={classes.header__fullPrice}>
                        <span>{fullPrice} ₽</span>
                    </div>
                </div>
                <div className={classes.schedule}>
                 <div className={classes.schedule__item}>
                    <span>Сегодня</span>
                    <span>{splitPrice} ₽</span>
                 </div>
                 <div className={classes.schedule__item}>
                    <span>{dayjs().add(14, 'day').format('DD MMMM YYYY')}</span>
                    <span>{splitPrice} ₽</span>
                 </div>
                 <div className={classes.schedule__item}>
                 <span>{dayjs().add(28, 'day').format('DD MMMM YYYY')}</span>
                    <span>{splitPrice} ₽</span>
                 </div>
                 <div className={classes.schedule__item}>
                 <span>{dayjs().add(42, 'day').format('DD MMMM YYYY')}</span>
                    <span>{splitPrice} ₽</span>
                 </div>
                </div>
                <div className={classes.button}>
                    <button onClick={handleForward}>Оформить покупку</button>
                </div>
            </div>
        </div>
    )
}