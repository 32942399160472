import React, { useEffect } from "react";
import classes from "./style.module.scss";
import { ProductSlider, ProductInfo } from "../../components/Offer";
import arrow from "../../images/back.svg";
import useMPIntegration from "../../hooks/useMPIntegration";
import { EWebViewRoute } from "../../types";

export const Offer = () => {
  const { notifyMobileOfUrlChange } = useMPIntegration();

  useEffect(() => {
    notifyMobileOfUrlChange(EWebViewRoute.Offer);
  }, [notifyMobileOfUrlChange]);

  const handleBack = () => {
    notifyMobileOfUrlChange(EWebViewRoute.BackToShops);
    window.location.href = "https://podeli.ru/app-shop";
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.back}>
        <button onClick={handleBack}>
          <img src={arrow} alt="arrow" />
        </button>
      </div>
      <ProductSlider />
      <ProductInfo />
    </div>
  );
};
