import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Path } from './types';
import { Offer, Schedule, Error } from './pages';
import './index.scss'

const router = createBrowserRouter([
  {
    path: Path.Root,
    element: <></>,
  },
  {
    path: Path.Offer,
    element: <Offer/>,
  },
  {
    path: Path.Schedule,
    children: [
      {
        path: ':id',
        element: <Schedule />,
      }
    ]
  },
  {
    path: Path.Error,
    element: <Error />
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
