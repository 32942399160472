import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { bestOffers, offers } from '../../common/mock';
import { ProductSchedule } from '../../components/Schedule';
import useMPIntegration from '../../hooks/useMPIntegration';
import { EWebViewRoute } from '../../types';

export const Schedule = () => {
    let { id } = useParams();
    const { notifyMobileOfUrlChange } = useMPIntegration();

    useEffect(() => {
      notifyMobileOfUrlChange(EWebViewRoute.Schedule);
    }, [notifyMobileOfUrlChange]);

    const product = useMemo(() => {
        return [...bestOffers, ...offers].find((item) => item.id === Number(id))
    }, [id])

    console.log(product)

    return (
        <React.Fragment>
            {product && <ProductSchedule img={product.img} fullPrice={product.fullPrice} splitPrice={product.splitPrice}  name={product.name} description={product.description}/>}
        </React.Fragment>
    )
}