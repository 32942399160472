import rivGoshIcon from "../images/rivgosh.svg";
import letualIcon from "../images/letual.svg";
import randewoo from "../images/goodnight.svg";

export const bestOffers = [
  {
    id: 0,
    img: randewoo,
    fullPrice: "3 156",
    splitPrice: "789",
    best: true,
    name: "Randewoo",
    description: "Магазин парфюмерии"
  }
];

export const offers = [
  {
    id: 1,
    img: letualIcon,
    fullPrice: "3 674",
    splitPrice: "918",
    best: false,
    name: "Летуаль",
    description: "Магазин парфюмерии"
  },
  {
    id: 2,
    img: rivGoshIcon,
    fullPrice: "3 699",
    splitPrice: "925",
    best: false,
    name: "РИВ ГОШ",
    description: "Магазин парфюмерии"
  }
];
