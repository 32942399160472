import React, { useEffect } from 'react';
import { ErrorComponent } from '../../components/Error';
import useMPIntegration from '../../hooks/useMPIntegration';
import { EWebViewRoute } from '../../types';

export const Error = () => {
    const { notifyMobileOfUrlChange } = useMPIntegration();

    useEffect(() => {
      notifyMobileOfUrlChange(EWebViewRoute.Error);
    }, [notifyMobileOfUrlChange]);
    
    return (
        <React.Fragment>
           <ErrorComponent/>
        </React.Fragment>
    )
}