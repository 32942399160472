import React from "react";
import classes from "./style.module.scss";
import star from "../../images/star.svg";
import { ProductOffer } from "./ProductOffers";
import { bestOffers, offers } from "../../common/mock";
import { useNavigate } from "react-router-dom";
import { Path } from "../../types";

export const ProductInfo = () => {
  const navigate = useNavigate();
  const handleClick = (id: number) => {
    navigate(`${Path.Schedule}/${id}`);
  };

  const finalOffer = bestOffers[0];
  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <h2 className={classes.title}>Lanvin Eclat D'Arpege</h2>
        <span className={classes.description}>Парфюмерная вода 50 мл.</span>
        <div className={classes.rating}>
          {Array(5)
            .fill(null)
            .map((_, index) => (
              <img key={index} src={star} alt="star" />
            ))}
          <span className={classes.rating__amount}>4.8</span>
          <span className={classes.rating__reviews}>(567 отзывов)</span>
        </div>
        <h3 className={classes.subtitle}>О товаре</h3>
        <p className={classes.content}>
          Чувственный и тонкий аромат Lanvin Eclat Darpege (духи Ланвин Эклат
          Д'арпеж) подарит своей поклоннице яркие, лучистые и ослепительные
          краски жизни на каждый день, сделает ее еще более женственной,
          прекрасной и обаятельной. Аромат вызывает ассоциации с рассветом в
          самом известном романтическом городе мира Париже, когда на небе только
          появляются нежные розовые тона, а в воздухе становятся слышны голоса
          птиц.
        </p>

        <p className={classes.content}>
          Аромат «Осколок Арпеджио» создал в 2002 году талантливый парфюмер
          Карин Дюбриель. Создал для молодых женщин, легко и беззаботно идущих
          по жизни, покоряя всех на своем пути. В 2007 году ценительницам
          данного парфюма был преподнесен подарок в виде его новой вариации
          Lanvin Eclat DArpege SUMMER.
        </p>

        <p className={classes.content}>
          Звучание аромата Eclat Darpege начинается с сочетания нот зеленой
          сирени и листьев сицилийского лимона. Воздушные цветочные ноты
          красного пиона, листьев зеленого чая, китайского османтуса, цветков
          глицинии и персика в сердце аромата создают сияние нежности и
          сладострастия. Чарующий шлейф из нот амбры, белого ливанского кедра,
          зверобоя и сладкого мускуса подчеркивает изящность и очарование
          обладательницы этого притягательного аромата.
        </p>

        <h3 className={classes.subtitle}>Характеристики</h3>
        <div className={classes.table}>
          <div className={classes.table__item}>
            <span>Объем флакона</span>
            <span>50 мл.</span>
          </div>
          <div className={classes.table__item}>
            <span>Тип</span>
            <span>Туалетная вода</span>
          </div>
          <div className={classes.table__item}>
            <span>Пол</span>
            <span>Женский</span>
          </div>
          <div className={classes.table__item}>
            <span>Год создания</span>
            <span>2023</span>
          </div>
        </div>
        <ProductOffer
          title="Лучшая цена"
          items={bestOffers}
          handleClick={handleClick}
          withMargin
        />
        <ProductOffer
          title="Другие предложения"
          handleClick={handleClick}
          items={offers}
        />
      </div>
      <div className={classes.finalOffer}>
        <div className={classes.finalOffer__amount}>
          <span>
            {finalOffer.splitPrice} ₽ <span>x</span> 4
          </span>
          <span>{finalOffer.fullPrice} ₽</span>
        </div>
        <button onClick={() => handleClick(finalOffer.id)}>Купить</button>
      </div>
    </React.Fragment>
  );
};
