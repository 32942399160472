import React from 'react';
import cn from 'classnames'
import classes from './style.module.scss'

interface Props {
    items: Array<any>
    title: string;
    withMargin?: boolean;
    handleClick: (id: number) => void;
}

export const ProductOffer:React.FC<Props> = ({ items, title, withMargin, handleClick }) => {
    return (
        <React.Fragment>
             <h3 className={cn(classes.subtitle, withMargin && classes.mt70)}>{title}</h3>
             <div className={classes.offers}>
                {items.map(({ img, fullPrice, splitPrice, best, id}) => {
                    return (
                        <div key={id} className={cn(classes.offer, best && classes.best)}>
                            <img src={img} alt='icon'/>
                            <div className={classes.offer__price}>
                                <span className={classes.offer__price_split}>{splitPrice} ₽ <span>x</span> 4</span>
                                <span className={classes.offer__price_full}>{fullPrice} ₽</span>
                            </div>
                            <button onClick={() => handleClick(id)}>Купить</button>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}