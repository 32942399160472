export const enum Path {
  Root = "/",
  Offer = "/product_offer",
  Schedule = "/product_schedule",
  Error = "/product_error"
}

export enum EWebViewRoute {
  Offer = "product_offer",
  Schedule = "product_schedule",
  Error = "product_error",
  BackToShops = "back_to_shops"
}

export enum EWebViewEvent {
  WebEvent = "webEvent"
}
