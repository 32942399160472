import React from "react";
import classes from "./style.module.scss";
import errorIcon from "../../images/error.svg";
import useMPIntegration from "../../hooks/useMPIntegration";
import { EWebViewRoute } from "../../types";

export const ErrorComponent = () => {
  const { notifyMobileOfUrlChange } = useMPIntegration();

  const handleNavigateToShop = () => {
    notifyMobileOfUrlChange(EWebViewRoute.BackToShops);
    window.location.href = "https://podeli.ru/app-shop";
  };

  return (
    <div className={classes.wrapper}>
      <img src={errorIcon} alt="error" className={classes.image} />
      <h3 className={classes.title}>
        Данный раздел находится <br /> в разработке
      </h3>
      <p className={classes.description}>
        Приносим свои извинения <br /> за неудобство,{" "}
        <br className={classes.descriptionOffset} style={{ display: "none" }} />{" "}
        товар можно приобрести <br /> в магазине партнера
      </p>
      <div className={classes.button}>
        <button onClick={handleNavigateToShop}>Вернуться в магазин</button>
      </div>
    </div>
  );
};
